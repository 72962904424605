import { getUserData } from '@/auth/utils';
import { ref, watch, computed } from '@vue/composition-api';
import store from '@/store';

// Notification
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { useToast } from 'vue-toastification/composition';

export default function useTenderEditTabVisitsList() {

	// Use toast
	const toast = useToast();
	const userData = getUserData();

	const refTenderBidderVisitsListTable = ref(null);

	const styleColumns = {
		thClass: 'text-center',
		tdClass: 'text-center'
	}

	// Table Handlers
	let tableColumns = [
		{ key: 'tender_code', sortable: true, label: 'CÓDIGO <br/> LICITACIÓN', ...styleColumns },
		{ key: 'bidder_name', sortable: true, label: 'POSTOR', ...styleColumns },
		{ key: 'fullname', sortable: true, label: 'VISITANTE <br/> REGISTRADO', thClass: styleColumns.thClass },
		{ key: 'documents', sortable: false, label: 'DOCUMENTOS', ...styleColumns },
		{ key: 'attendances', sortable: true, label: 'REGISTRO <br/> ASISTENCIAS', ...styleColumns },
		{ key: 'actions', sortable: false, label: 'OPCIONES', ...styleColumns }
	];

	const perPage = ref(10);
	const totalVisits = ref(0);
	const currentPage = ref(1);
	const perPageOptions = [10, 25, 50, 100];
	const searchQuery = ref('');
	const sortBy = ref('createdAt');
	const isSortDirDesc = ref(true);

	const dataMeta = computed(() => {
		const localItemsCount = refTenderBidderVisitsListTable.value ? refTenderBidderVisitsListTable.value.localItems.length : 0;
		return {
			from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
			to: perPage.value * (currentPage.value - 1) + localItemsCount,
			of: totalVisits.value,
		}
	});

	const refetchData = () => {
		refTenderBidderVisitsListTable.value.refresh();
	};

	watch([currentPage, perPage, searchQuery], () => {
		refetchData();
	});

	const fetchVisits = (ctx, callback) => {
		store
			.dispatch('app-visit/fetchVisits', {
				q: searchQuery.value,
				perPage: perPage.value,
				page: currentPage.value,
				sortBy: sortBy.value,
				sortDesc: isSortDirDesc.value,
			})
			.then(response => {
				const { visits, total } = response.data;
				callback(visits);
				totalVisits.value = total;
			})
			.catch(() => {
				toast({
					component: ToastificationContent,
					props: {
						title: 'Error al obtener la lista de visitantes',
						icon: 'AlertTriangleIcon',
						variant: 'danger'
					}
				});
			})
	};

	return {
		fetchVisits,
		tableColumns,
		perPage,
		currentPage,
		totalVisits,
		dataMeta,
		perPageOptions,
		searchQuery,
		sortBy,
		isSortDirDesc,
		refTenderBidderVisitsListTable,

		refetchData,
	}
}