<template>
	<div>
		<!-- Table Container Card -->
		<b-card
			no-body
			class="mb-0"
		>
			<div class="m-2">
				<!-- Table Top -->
				<b-row>
					<!-- Per Page -->
					<b-col
						cols="12"
						md="4"
						class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
					>
						<label>Mostrar</label>
						<v-select
							v-model="perPage"
							:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
							:options="perPageOptions"
							:clearable="false"
							class="per-page-selector d-inline-block mx-50"
						/>
						<label>registros</label>
					</b-col>

					<!-- Search -->
					<b-col
						cols="12"
						md="6"
						class="ml-auto"
					>
						<div class="d-flex">
							<b-form-input
								v-model="searchQuery"
								class="d-inline-block mr-1"
								placeholder="Buscar ..."
								trim
							/>
						</div>
					</b-col>
				</b-row>
			</div>

			<b-table
				ref="refTenderBidderVisitsListTable"
				class="position-relative table-tenders"
				:style="totalVisits >= 1 && totalVisits <= 3 ? 'height: 280px' : 'height: inherit'"
				:items="fetchVisits"
				responsive
				:fields="tableColumns"
				primary-key="id2"
				:sort-by.sync="sortBy"
				show-empty
				empty-text="No se encontraron registros coincidentes"
				:sort-desc.sync="isSortDirDesc"
			>
				<template #table-busy>
					<div class="text-center text-primary my-2">
						<b-spinner class="align-middle mb-1"></b-spinner>
						<div>
							<b>CARGANDO</b>
						</div>
					</div>
				</template>

				<template #head(tender_code)="data">
					<span v-html="data.label"></span>
				</template>

				<template #head(fullname)="data">
					<span v-html="data.label"></span>
				</template>

				<template #head(attendances)="data">
					<span v-html="data.label"></span>
				</template>

				<template #cell(bidder_name)="data">
					<div class="text-nowrap">{{ data.item.bidder_name }}</div>
				</template>

				<template #cell(fullname)="data">
					<b-media
						vertical-align="center"
						class="text-nowrap"
					>
						<template #aside>
							<b-avatar
								size="32"
								:src="null"
								:text="avatarText(data.item.fullname)"
								variant="light-success"
							/>
						</template>
						<b-link class="font-weight-bold d-block">{{ data.item.fullname }}</b-link>
						<div class="small text-muted">
							<strong>DNI:</strong>
							{{ data.item.dni }}
						</div>
						<div class="small text-muted">
							<strong>CARGO:</strong>
							{{ data.item.position }}
						</div>
					</b-media>
				</template>

				<template #cell(documents)="data">
					<div class="text-nowrap">
						<b-button-upload
							entity="COMPETITIONS"
							:entity-id="data.item.competition_id"
							:is-disabled="true"
							:competition-props="{ requestId: data.item.request_id, isVisit: true, ownerId: data.item.id }"
							:modal-props="{ title: 'DOCUMENTOS DE VISITA' }"
							:button-props="{  onlyIcon: true, size: 'sm' }"
						/>
					</div>
				</template>

				<template #cell(attendances)="data">
					<div>
						<span
							v-for="(attendance, index) in getAttendances(data.item.attendances)"
							:key="`${index + data.item.id }`"
						>
							<b-button
								:id="`tooltip-target-${data.item.id}-${index}`"
								v-ripple.400="'rgba(113, 102, 240, 0.15)'"
								variant="flat-success"
								class="btn-icon rounded-circle"
							>
								<feather-icon icon="CheckCircleIcon" />
							</b-button>
							<b-tooltip
								:target="`tooltip-target-${data.item.id}-${index}`"
								triggers="hover"
								no-fade
							>{{ attendance ? moment(attendance).format('LLL') : '' }}</b-tooltip>
						</span>
					</div>
				</template>

				<template #cell(actions)="data">
					<b-dropdown
						variant="link"
						no-caret
						:right="$store.state.appConfig.isRTL"
					>
						<template #button-content>
							<feather-icon
								icon="MoreVerticalIcon"
								size="16"
								class="align-middle text-body"
							/>
						</template>

						<b-dropdown-item @click="openAttendanceModal(data.item)">
							<feather-icon icon="UserCheckIcon" />
							<span class="align-middle ml-50">Registrar Asistencia</span>
						</b-dropdown-item>
					</b-dropdown>
				</template>
			</b-table>

			<div class="mx-2 mb-2">
				<b-row>
					<b-col
						cols="12"
						sm="6"
						class="d-flex align-items-center justify-content-center justify-content-sm-start"
					>
						<span
							class="text-muted"
						>Mostrando {{ dataMeta.from }} al {{ dataMeta.to }} de {{ dataMeta.of }} registros</span>
					</b-col>

					<b-col
						cols="12"
						sm="6"
						class="d-flex align-items-center justify-content-center justify-content-sm-end"
					>
						<b-pagination
							v-model="currentPage"
							:total-rows="totalVisits"
							:per-page="perPage"
							first-number
							last-number
							class="mb-0 mt-1 mt-sm-0"
							prev-class="prev-item"
							next-class="next-item"
						>
							<template #prev-text>
								<feather-icon
									icon="ChevronLeftIcon"
									size="18"
								/>
							</template>
							<template #next-text>
								<feather-icon
									icon="ChevronRightIcon"
									size="18"
								/>
							</template>
						</b-pagination>
					</b-col>
				</b-row>
			</div>
		</b-card>

		<b-modal
			ref="attendanceModal"
			title-tag="div"
			header-bg-variant="primary"
			header-text-variant="white"
			centered
			title="REGISTRO DE ASISTENCIA DEL VISITANTE"
			no-close-on-backdrop
			no-close-on-esc
			hide-header-close
		>
			<b-form>
				<b-col
					cols="12"
					class="my-2"
				>
					<b-form-group>
						<label for="attendance">
							<strong>Ingrese fecha y hora</strong>
						</label>
						<flat-pickr
							id="attendance"
							class="form-control"
							:config="onlyDateConfig"
							v-model="visitor_attendance"
							placeholder="DD-MM-YYYY HH:mm"
							aria-required="true"
							required
							trim
						/>
					</b-form-group>
				</b-col>
			</b-form>

			<template #modal-footer="{ cancel }">
				<b-button
					@click="saveAttendance()"
					variant="primary"
				>REGISTRAR</b-button>

				<b-button
					v-ripple.400="'rgba(186, 191, 199, 0.15)'"
					type="button"
					variant="outline-secondary"
					class="ml-2"
					@click="cancel"
				>CERRAR</b-button>
			</template>
		</b-modal>
	</div>
</template>

<script>
import { ref, onUnmounted } from "@vue/composition-api"
import BButtonUpload from "@core/components/b-button-upload/BButtonUpload.vue"
import { VBModal, VBTooltip } from "bootstrap-vue"
import useVisitList from "./useVisitsList"
import visitStoreModule from "../visitStoreModule"
import Ripple from "vue-ripple-directive"
import { avatarText } from "@core/utils/filter"
import flatPickr from "vue-flatpickr-component"
import { Spanish } from "flatpickr/dist/l10n/es"
import useNotifications from "@notifications"
import vSelect from "vue-select"
import store from "@/store"
import moment from "moment"
import "animate.css"
moment.locale("es")

export default {
	components: {
		vSelect,
		flatPickr,

		BButtonUpload
	},
	directives: {
		"b-modal": VBModal,
		"b-tooltip": VBTooltip,
		Ripple
	},
	data() {
		return {
			moment
		}
	},
	setup() {
		const VISIT_APP_STORE_MODULE_NAME = "app-visit"

		// REGISTER MODULE
		if (!store.hasModule(VISIT_APP_STORE_MODULE_NAME))
			store.registerModule(VISIT_APP_STORE_MODULE_NAME, visitStoreModule)

		// UNREGISTER ON LEAVE
		onUnmounted(() => {
			if (store.hasModule(VISIT_APP_STORE_MODULE_NAME))
				store.unregisterModule(VISIT_APP_STORE_MODULE_NAME)
		})

		const { swalNotification, toastNotification } = useNotifications()

		// REFS
		const options = ref({})
		const attendanceModal = ref(null)
		const visitor_attendance = ref(null)
		const visitor_attendances = ref({})
		const visitId = ref(null)
		const tenderId = ref(null)
		const onlyDateConfig = ref({
			enableTime: true,
			locale: Spanish,
			dateFormat: "d-m-Y H:i",
			minDate: moment().format("DD-MM-YYYY")
		})

		const openAttendanceModal = ({ id, tender_id }) => {
			visitId.value = id
			tenderId.value = tender_id
			visitor_attendance.value = null

			attendanceModal.value.show()
		}

		const getAttendances = (items) => {
			if (!items) return []

			return JSON.parse(items)
		}

		const saveAttendance = () => {
			if (!visitor_attendance.value) {
				toastNotification("Complete el campo requerido", false)
				return
			}

			const currentAttendances = visitor_attendances.value[visitId.value]
				? JSON.parse(visitor_attendances.value[visitId.value])
				: []
			const attendance = moment(
				visitor_attendance.value,
				"DD-MM-YYYY H:mm",
				"America/Lima"
			).format()

			currentAttendances.push(attendance)

			const payload = {
				attendances: currentAttendances,
				tender_id: tenderId.value
			}

			store
				.dispatch("app-visit/recordAttendance", { id: visitId.value, payload })
				.then((response) => {
					visitor_attendances.value[visitId.value] =
						JSON.stringify(currentAttendances)
					refetchData()
					attendanceModal.value.hide()
					swalNotification(response.data.message)
				})
				.catch((error) => {
					const message =
						error.response.data.message || "Error al registrar asistencia"
					swalNotification(message, false)
				})
		}

		const {
			fetchVisits,
			tableColumns,
			perPage,
			currentPage,
			totalVisits,
			dataMeta,
			perPageOptions,
			searchQuery,
			sortBy,
			isSortDirDesc,
			refTenderBidderVisitsListTable,
			refetchData
		} = useVisitList()

		return {
			fetchVisits,
			tableColumns,
			options,
			perPage,
			currentPage,
			totalVisits,
			dataMeta,
			perPageOptions,
			searchQuery,
			sortBy,
			isSortDirDesc,
			refTenderBidderVisitsListTable,
			refetchData,

			// ACTIONS
			avatarText,

			// REFS
			visitor_attendance,
			attendanceModal,
			onlyDateConfig,

			// METHODS
			openAttendanceModal,
			saveAttendance,
			getAttendances
		}
	}
}
</script>

<style lang="scss" scoped>
.media {
	align-items: center;
}

.per-page-selector {
	width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>